export class Common {

    constructor() {
    }

    static getValue(selector) {

        return (document.querySelector(selector) || {}).value;
    }

    static isEmail(email) {

        const emailRegex = /(\w+)\@(\w+)\.[a-zA-Z]/g;

        return emailRegex.test(email);
    }

    static get antiForgeryToken() {

        return "8817d13120364198904890ab87f1d2b778a60cd5e68d4068b76a27ba25916e0061348d5d6626473b81ad98945708cd8f";
    }
}

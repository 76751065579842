import '../site.scss'
import './index.scss'
import {Common} from '../common';

let sendEl = document.querySelector("#send");
if (sendEl) sendEl.addEventListener('click', send);

function send(e) {

    e.preventDefault();

    grecaptcha.ready(async function () {

        let token = await grecaptcha.execute('6LcxG4IaAAAAAOq7L5Ahd_2qrCGd0lrTD2mfVB3X', {action: 'submit'});

        let verify = Common.getValue("#verify");
        let name = Common.getValue("#name");
        let replyTo = Common.getValue("#replyTo");
        let message = Common.getValue("#message");

        if (verify) return; // honeypot

        let hasError = false;

        if (!name) {
            setError("#name");
            hasError = true;
        }

        if (!Common.isEmail(replyTo)) {
            setError("#replyTo");
            hasError = true;
        }

        if (!message) {
            setError("#message");
            hasError = true;
        }

        if (hasError) {
            return;
        }

        sendEl.removeEventListener('click', send);
        sendEl.innerText = 'Sending...'

        let request = {
            action: "contact",
            antiForgeryToken: Common.antiForgeryToken,
            data: {
                topic: 'Fennec Studio Marketing Contact',
                name,
                replyTo,
                message,
            }
        };

        const xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState !== 4) return;
            if (this.status !== 200) console.error(this);
            document.querySelector('.contact-form').style.display = 'none';
            document.querySelector('.contact-confirmation').style.display = 'block';
        };
        xhttp.open('POST', 'https://api.fennec.studio/api', true);
        xhttp.send(JSON.stringify(request))

    });
}

function setError(selector) {
    document.querySelector(selector).classList.add('error');
    document.querySelector(".error-message").style.display = 'block';
    document.querySelector(".leadin-message").style.display = 'none';
    return false;
}

